<template>
    <SKModal
        ref="modal"
        actionButtonsPosition="right"
        :hasAcceptButton="false"
        :fullscreen="true"
        title=""
    >
        <template v-slot:body>
            <img class="photo-modal-image" :src="image"/>
        </template>
    </SKModal>
</template>

<script>
import SKModal from "@/modules/Shared/Components/modal/SKModal";

export default {
    name: 'PreviewPhotoModal',
    props: {
        image: {
            type: String,
            required: false
        },
    },
    components: {
        SKModal,
    },
    methods: {
        open() {
            this.$refs['modal'].open()
        }
    },
}
</script>

<style scoped>
    .photo-modal-image {
        max-width: 90vw;
        max-height: 75vh;
        width: auto;
        height: auto;
        display: block;
        margin: auto;
    }
</style>
