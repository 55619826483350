import axios from '@/axios.js'

const baseUrl = `v4/countries`;

export default {
    getCountries : async (params, page = 1) => {
        try {
            const response = await axios.publicapi.get(baseUrl, { params: {
                ...params,
                page: page
                }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }
}
