<template>
    <div class="flex flex-column bg-white rounded-lg shadow-md">
        <div :class="`w-64 h-64 flex items-center justify-end rounded-lg border-solid border-3 border-${isPublished ? 'success' : 'danger'}`" :style="`background-color: whitesmoke; background-image: url(${thumbnail}); background-size: contain; background-repeat: no-repeat; background-position: center;`">
            <div class="flex flex-column justify-between h-full p-2">
                <SKTooltip :text="isPublic ? $t('admin.photos.public') : $t('admin.photos.private')">
                    <feather-icon :icon="isPublic ? 'EyeIcon' : 'EyeOffIcon'" svgClasses="w-5 h-5" class="photo-card-icon text-white"/>
                </SKTooltip>
                <SKTooltip :text="isPublished ? $t('admin.photos.published') : $t('admin.photos.unpublished')">
                    <feather-icon :icon="isPublished ? 'CheckCircleIcon' : 'XCircleIcon'" svgClasses="w-5 h-5" class="photo-card-icon text-white"/>
                </SKTooltip>
            </div>
        </div>
        <div class="flex flex-column gap-2 p-4">
            <h5 class="mb-0">{{ username }}</h5>
            <p class="mb-0 text-sm">{{application}} {{resort ? `(${resort})` : ''}}</p>
            <div class="flex flex-row gap-2">
                <button class="sk-btn sk-btn-small sk-btn-primary btn-border rounded-lg" @click="$emit('preview')">
                    {{ $t('admin.photos.preview') }}
                </button>
                <button class="sk-btn sk-btn-small sk-btn-primary btn-border rounded-lg" @click="$emit('publishChange')">
                    {{ isPublished ? $t('admin.photos.unpublish')  : $t('admin.photos.publish') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SKTooltip from "@/modules/Shared/Components/SKTooltip.vue";

export default {
    name:'PhotoCard',
    components: {SKTooltip},
    props: {
        username: {type: String, required: true},
        thumbnail: {type: String, required: true},
        isPublic: {type: Boolean, required: true},
        isPublished: {type: Boolean, required: true},
        application: {type: String, required: true},
        resort: {type: String, required: false},
    },
}
</script>

<style scoped>
    .photo-card-icon {
        filter: drop-shadow(0px 1px 3px black);
    }
</style>
